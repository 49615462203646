const words = {
  en: [
    "an innovative idea?",
    "a bold vision?",
    "a game-changing concept?",
    "an extraordinary plan?",
    "a groundbreaking thought?",
    "a brilliant spark?",
    "a fresh perspective?",
  ],
  sr: [
    "inovativnu ideju?",
    "smelu viziju?",
    "revolucionarni koncept?",
    "ekstraordinarni plan?",
    "prekretnu misao?",
    "sjajnu iskru?",
    "svežu perspektivu?",
  ],
};

let i = 0;
let j = 0;
let currentWord = "";
let isDeleting = false;

function type(language) {
  let timeout = 0;
  console.log(words[language]);
  currentWord = words[language][i];
  if (isDeleting) {
    document.getElementById("typewriter").textContent = currentWord.substring(
      0,
      j - 1
    );
    j--;
    if (j == 0) {
      isDeleting = false;
      i++;
      if (i == words[language].length) {
        i = 0;
      }
    }
  } else {
    document.getElementById("typewriter").textContent = currentWord.substring(
      0,
      j + 1
    );
    j++;
    if (j == currentWord.length) {
      isDeleting = true;
      timeout = 1600;
    }
  }
  setTimeout(() => {
    type(language);
  }, 40 + timeout);
}

let language = "en";
if (window.location.pathname.includes("/sr/")) {
  language = "sr";
}
console.log("language:", language);
type(language);
